import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-oryx.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/oryx-website/oryx-hero.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const OryxWebsite = function (props, ref) {

    const data = useStaticQuery(graphql`
        query OryxWebsiteQuery {
            heropic: file(relativePath: { eq: "images/projekte/oryx-website/thumbleiste/oryx-website-02.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/oryx-website/thumbleiste/oryx-website-02.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/oryx-website/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: BLURRED, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }                                     
        }
    `)
  
    const specsData = [
        { title: "Eckdaten", objects: [logo, "Webseite", "https://www.oryx.com"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "wordpress"],
        },
      ]
    return (
        <Layout>
            <SEO
                title="Oryx Stainless Website"
                description="Q:marketing realisiert Website-Relaunch für Rohstoffhändler."
                image={data?.ogimage?.childImageSharp?.fixed}
            />
                        
            {/* posterImage={data.heropic.childImageSharp.gatsbyImageData}                         */}
            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>Oryx.com</h1>
                        <p className="introtext">Trust in Recycling.<br/>Website für Mülheimer Rohstoffhändler.</p>
                        <AnchorLink to="/projekte/websites/oryx/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    

            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Darstellung von Größe und Internationalität.",
                        "Überarbeitung des Bild- und Textkonzepts.",
                        "Für Lieferanten, Kunden und Interessenten bietet die Seite ausführliche Informationen zu den Leistungen des Unternehmens und persönliche Kontaktmöglichkeiten zu den Händlern.",
                        "Redaktionssystem zur sebstständigen Bearbeitung durch den Kunden.",
                        "Der Oryx Stainless Newsletter informiert über branchenrelevante Themen."                        
                    ]}
                />
            </ListenModule>
            

            <Specs data={specsData} />                       
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default OryxWebsite